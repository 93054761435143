.uk-open {
  button {
    svg {
      transform: rotate(-180deg);
      transition: transform 0.3s;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
